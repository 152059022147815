import React from "react";
import styles from './styles.module.scss';
import { IoIosSchool } from "react-icons/io";

export default class DefaultSignInComponent extends React.PureComponent {
  render = () => {
    return (
      <div className={styles.signIn}>
        <div className="primary-background sign-in-content">
          <div className="row justify-content-center">
            <div className={styles.title}>
              <div>
                <p className={styles.boldTitle}>SWIFT <span><IoIosSchool /></span></p>
                <p>ACADEMY</p>
              </div>
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
