import React from 'react';
import {connect} from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import {IoIosArrowDown, IoIosRemoveCircleOutline, IoIosCheckmarkCircleOutline, IoIosArrowForward} from "react-icons/io";

import {getCurrentLesson, getSectionLessons, getCourseSections} from '../../actions';
import styles from './styles.module.scss';

class LessonIndexComponent extends React.Component {

  state = {
    lessonTouched: false,
    key: this.props.sectionId
  }

  componentDidMount() {
    this.getSectionLessons(this.props.sectionId);
  }

  componentDidUpdate(prevProps) {
    if (this.state.lessonTouched) {
      const lessonId = this.props.lessonId;
      const courseId = prevProps.courseId;
      this.props.getCurrentLesson(lessonId, courseId, null)
    }
    if (prevProps.sectionId !== this.props.sectionId) {
      this.getSectionLessons(this.props.sectionId);
      this.setState({key: this.props.sectionId})
    }
  }

  lessonClick = () => {
    this.setState({lessonTouched: true})
  }

  getSectionLessons = (sectionId) => {
    this.props.getSectionLessons(sectionId);
  }

  createSectionIndex = () => {
    const courseId = this.props.courseId
      ? this.props.courseId
      : this.props.match.params.id
    return Object.values(this.props.courseSections.sections).map((section, sectionIndex) => {
      return (<Card key={sectionIndex} className={`border-bottom-1 ${styles.expandedLessonNavigator}`}>
        <Accordion.Toggle onClick={() => this.getSectionLessons(section.id)} as={Card.Header} eventKey={section.id}>
          <div className={styles.expandedLessonNavigatorLesson}>
            <div className={styles.uiAccordionIndicator}>
              {
                this.state.key === section.id
                  ? <IoIosArrowDown size="2em" color="#2E3C63"/>
                  : <IoIosArrowForward size="2em" color="#2E3C63"/>
              }
            </div>
            <div className={styles.uiAccordionSection}>
              <h4>
                <div className={styles.uiAccordionSectionInfo}>
                  <div className={styles.uiAccordionSectionTitle}>
                    {`${section.sectionIndex + 1}: ${section.attributes.title}`}
                  </div>
                  <small>
                    {section.attributes['video-summary']['number_of_videos']} videos |
                    total: {Math.round(section.attributes['video-summary']['duration']/60)} minutes
                  </small>
                </div>
              </h4>
            </div>
          </div>
        </Accordion.Toggle>
        {
          Object.keys(this.props.sectionLessons.lessons).length > 0
            ? Object.values(this.props.sectionLessons.lessons).map((lesson, lessonIndex) => {
              const vidMins = Math.floor(lesson.attributes.duration / 60)
              let vidSecs = lesson.attributes.duration % 60
              vidSecs = vidSecs < 10
                ? "0" + vidSecs
                : vidSecs
              return (<Accordion.Collapse eventKey={section.id} key={lesson.id}>
                <ListGroup className={styles.expandedLesson}>
                  <ListGroup.Item className={this.props.lessonId === lesson.id
                      ? styles.currentLesson
                      : styles.nonActiveLesson
                  }>
                    <Card.Body className={`${styles.lessonLinkContainer}`}>
                      <div>
                        <Link className='lessonLink' style={{
                            textDecoration: 'none'
                          }} onClick={this.lessonClick} to={`/courses/${courseId}/lessons/${lesson.id}`}>
                          <div>
                            <span className={styles.emptyCheckBox}>
                              {
                                this.props.completedLessons.includes(lesson.id) || lesson.attributes['has-lesson-event']
                                  ? <IoIosCheckmarkCircleOutline size="2em" color="#2C55C5" style={{
                                        backgroundColor: '#CADEDD',
                                        borderRadius: '50%'
                                      }}/>
                                  : <IoIosRemoveCircleOutline size="2em" color="#2C55C5" style={{
                                        backgroundColor: '#fff',
                                        borderRadius: '50%'
                                      }}/>
                              }
                            </span>
                          </div>
                          <div className={styles.lessonTitle}>
                            {lesson.attributes.title}
                          </div>
                        </Link>
                      </div>
                      <div className={styles.lessonTime}>
                        <p style={{
                            textAlign: 'right'
                          }}>
                          <span className={styles.lessonDuration}>
                            {vidMins}:{vidSecs}
                          </span>
                        </p>
                      </div>
                    </Card.Body>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Collapse>)
            })
            : null
        }
      </Card>)
    })
  }

  render = () => {
    return (
      Object.keys(this.props.sectionLessons.lessons).length > 0
      ? <Accordion activeKey={this.state.key} onSelect={(e) => this.setState({key: e})}>
        {this.createSectionIndex()}
      </Accordion>
      : 'Loading')
  }
}

const mapStateToProps = (state) => {
  return {courseSections: state.lms.courseSections, sectionLessons: state.lms.sectionLessons}
}

export default connect(mapStateToProps, {getSectionLessons, getCurrentLesson, getCourseSections})(LessonIndexComponent)
