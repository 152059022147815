import {
  createSelector
} from 'reselect';
// selector
const getCourseList = (state) => state.lms.courseList.courses
const getRecentCourses = (state) => state.lms.courseList.recentCourses
// reselect function
export const getCourseListState = createSelector(
  [getCourseList],
  (courseList) => courseList
)

export const getRecentCoursesState = createSelector(
  [getRecentCourses],
  (recentCourses) => recentCourses
)