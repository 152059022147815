import {
  combineReducers
} from 'redux';

import courseListReducer from './courseList';
import courseSectionsReducer from './courseSections';
import currentCourseReducer from './currentCourse';
import sectionLessonsReducer from './sectionLessons.js';
import lessonReducer from './lessonReducers';
import fetchedCourseReducer from './fetchedCourse';
import userReducer from './user';

export default combineReducers({
  courseList: courseListReducer,
  currentCourse: currentCourseReducer,
  courseSections: courseSectionsReducer,
  sectionLessons: sectionLessonsReducer,
  fetchedCourse: fetchedCourseReducer,
  lesson: lessonReducer,
  user: userReducer
})