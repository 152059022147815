import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import {IoIosPlay, IoLogoApple, IoIosDesktop} from "react-icons/io";
import {MdAndroid} from "react-icons/md";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styles from "./styles.module.scss";

export class CourseListItemComponent extends React.Component {

  platforms = (platformsArr) => {
    return platformsArr.map((platform, index) => {
      if (platform === 'ios') {
        return (<IoLogoApple size="2.5em" color="#2E3C63" key={index}/>)
      } else if (platform === 'android') {
        return (<MdAndroid size="2.5em" color="#2E3C63" key={index}/>)
      } else {
        return (<IoIosDesktop size="2.5em" color="#2E3C63" key={index}/>)
      }
    })
  }

  render = () => {
    return (<Col sm="12" md="6" lg="4" className={styles.courseItemCol}>
      <Card className={[`${ "mb-5"}`, styles.courseItemContainer, `${ "bg-swift-grey"}`]}>
        <div className={styles.courseImageContainer}>
          <img className={styles.courseImage} alt={'courseImage'} src="https://i.ibb.co/HXhGVks/2.png"/>
        </div>
        <Card.Body className={[`
            ${ 'text-swift-primary'}`, styles.descriptionContainer]}>
          <Card.Title className="text-swift-primary">
            {this.props.courseName}
          </Card.Title>
          <Card.Text className={[`
              ${ 'text-swift-primary'}`]}>
            <span className={styles.courseDescription}>
              {this.props.courseDescription}
            </span>
          </Card.Text>
        </Card.Body>
        <div className={styles.platformsContainer}>
          {this.platforms(this.props.platforms)}
        </div>
        <div className={styles.buttonContainer}>
          <div className="d-inline-flex align-items-center">
            <div className={[`${ "btn-group"} ${styles.playButton}`]}>
              <button onClick={this.props.getCurrentCourseAndLesson} className="btn text-white">
                {
                  this.props.courseCompletion > 0 && this.props.courseCompletion < 100
                    ? 'Resume'
                    : 'Play'
                }
                <IoIosPlay className={styles.playCourseIcon} size="1.25em" color="#FFFFFF"/>
              </button>
            </div>
            <div className={styles.progressContainer}>
              <CircularProgressbar value={this.props.courseCompletion} text={`${this.props.courseCompletion}%`} strokeWidth={16} styles={{
                  trail: {
                    stroke: '#CBCED8'
                  },
                  path: {
                    stroke: '#2E3C63'
                  }
                }}/>
            </div>
          </div>
        </div>
      </Card>
    </Col>)
  }
}
