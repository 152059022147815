import React from 'react';
import {connect} from 'react-redux';
import CardDeck from 'react-bootstrap/CardDeck';

import {getCourseListState, getRecentCoursesState} from '../../selectors/courseList';
import {selectCurrentCourseState} from '../../selectors/currentCourse';
import {firstCurrentLessonAndIncludeState} from '../../selectors/firstCurrentLessonAndInclude';
import {getCourseList, selectCurrentCourse, getFirstLesson, getRecentCourses} from '../../actions';
import {CourseListItemComponent} from "../course_list_item_component";
import SearchComponent from '../search_component';
import CarouselComponent from '../carousel_component';
import styles from "./styles.module.scss";

class CourseListComponent extends React.Component {

  state = {
    navToFirstLesson: false
  }

  componentDidMount() {
    this.props.getCourseList();
    this.props.getRecentCourses();
  }

  getCurrentCourseAndLesson = (course) => {
    this.setState({navToFirstLesson: true})
    this.props.selectCurrentCourse(course);
    this.props.getFirstLesson(course.id);
  }

  createCourseList = () => {
    const coursesArray = Object.values(this.props.courseList)
    if (coursesArray <= 0) {
      return <div>Loading...</div>
    }
    return coursesArray.map((course, index) => {
      return (<CourseListItemComponent courseName={course.attributes.name} courseDescription={course.attributes.description} key={course.id} courseImg={'https://i.vimeocdn.com/video/855784884_1920x1080.jpg?r=pad'} getCurrentCourseAndLesson={() => this.getCurrentCourseAndLesson(course)} courseCompletion={course.attributes['percent-completed']} platforms={course.attributes.platforms}/>)
    })
  }

  render = () => {
    const user = this.props.currentUser.user.id
      ? this.props.currentUser.user.attributes['first-name']
      : null
    return (<div>
      <section className={[`${ "jumbotron"} ${ "text-center"} ${ "pb-0"} ${ "jumbotron-fluid"}`, styles.jumboCourseList]}>
        <div className={styles.nameContainer}>
          <h1 className="jumbotron-heading text-swift-primary jumboTitle">Welcome Back, {user}.</h1>
        </div>
        <div className={styles.searchContainer}>
          <SearchComponent/>
        </div>
      </section>
      <div className="container">
        <div className={styles.coursesHeader}>
          <h3>
            Ready to jump back in?
          </h3>
        </div>
        <div className="row">
          <CarouselComponent recentCourses={this.props.recentCourses} getCurrentCourseAndLesson={(course) => this.getCurrentCourseAndLesson(course)}/>
        </div>
      </div>
      <div className="album py-5">
        <div className="container">
          <div className={styles.coursesHeader}>
            <h3>
              All Courses
            </h3>
          </div>
          <div className="row">
            <CardDeck>
              {this.createCourseList()}
            </CardDeck>
          </div>
        </div>
      </div>
    </div>)
  }
}

const mapStateToProps = (state) => {
  return {courseList: getCourseListState(state), currentCourse: selectCurrentCourseState(state), firstLesson: firstCurrentLessonAndIncludeState(state), recentCourses: getRecentCoursesState(state)}
}

export default connect(mapStateToProps, {getCourseList, selectCurrentCourse, getFirstLesson, getRecentCourses})(CourseListComponent)
