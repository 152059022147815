import {
  COURSES_FETCH,
  COURSES_FETCH_ERROR,
  RECENT_COURSES_FETCH,
  RECENT_COURSES_FETCH_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  courses: {},
  errorMessage: '',
  recentCoursesError: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COURSES_FETCH:
      return {
        ...state, courses: action.payload
      }
      case COURSES_FETCH_ERROR:
        return {
          ...state, errorMessage: action.error
        }
        case RECENT_COURSES_FETCH:
          return {
            ...state, recentCourses: action.payload
          }
          case RECENT_COURSES_FETCH_ERROR:
            return {
              ...state, recentCoursesError: action.error
            }
            default:
              return state;
  }
}