export const COURSES_FETCH = 'courses_fetch';
export const COURSES_FETCH_ERROR = 'course_fetch_error';
export const RECENT_COURSES_FETCH = 'recent_courses_fetch';
export const RECENT_COURSES_FETCH_ERROR = 'recent_courses_fetch_error';
export const COURSE_SELECT = 'course_select';
export const COURSE_SELECT_ERROR = 'course_select_error';
export const LESSON_GET_FIRST = 'lesson_get_first';
export const LESSON_GET_FIRST_ERROR = 'lesson_get_first_error';
export const LESSON_GET_CURRENT = 'lesson_get_current';
export const LESSON_GET_CURRENT_ERROR = 'lesson_get_current_error';
export const COURSE_FETCH = 'course_fetch';
export const COURSE_FETCH_ERROR = 'course_fetch_error'
export const USER_FETCH = 'user_fetch';
export const USER_FETCH_ERROR = 'user_fetch_error';

export const REQUEST_SECTIONS = 'request_sections';
export const REQUEST_SECTIONS_ERROR = 'request_sections_error';
export const REQUEST_SECTION_LESSONS = 'request_section_lessons';
export const REQUEST_SECTION_LESSONS_ERROR = 'request_section_lessons_error';
export const CLEAR_SECTIONS = 'clear_sections';
export const CLEAR_CURRENT_LESSON = 'clear_current_lesson';