import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {userFetch} from "../../actions";
import "./styles.scss";
import styles from './styles.module.scss';
import DefaultSignInComponent from "../default_sign_in_component";

const SignInComponent = () => {

  const { user } = useSelector(state => ({
      user: state.lms.user
  }))

  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (user.errorMessage.length > 0 ) {
      setIsFetching(false)
    }
  }, [user])

  const signIn = async (formData) => {
    if (formData.email === '') {
      return;
    } else {
      setIsFetching(true);
      dispatch(userFetch(formData.email, formData.password));
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    let formData = {
      email: userEmail,
      password: userPassword
    }
    signIn(formData)
  };

  const onEmailChangeHandler = event => {
    setUserEmail(event.target.value)
  }

  const onPasswordChangeHandler = event => {
    setUserPassword(event.target.value)
  }

  const form = (<form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-xs-12 col-sm-4 offset-sm-4">
        <div className="form-group">
          <input onChange={(event) => onEmailChangeHandler(event)} type="email" className="form-control" id="signInEmail" name="email" placeholder="Email" value={userEmail}/>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-4 offset-sm-4">
        <div className="form-group">
          <input onChange={(event) => onPasswordChangeHandler(event)} type="password" className="form-control" id="signInPassword" name="password" placeholder="Password" value={userPassword}/>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-4 offset-sm-4 text-center">
        <button disabled={isFetching} className="btn dashboard-btn">
          {isFetching ?
            'loading...' :
            'Sign In'
          }
        </button>
      </div>
    </div>
    {user.errorMessage.includes('404') ?
      <div className={`row justify-content-center ${styles.errorMessage}`}>
        Your Credentials Seem To Be Incorrect
      </div> :
      null
    }
  </form>);

  return (<div className="sign-in-view">
    <DefaultSignInComponent>{form}</DefaultSignInComponent>
  </div>)
};

export {
  SignInComponent
};
