import {
  USER_FETCH,
  USER_FETCH_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  user: {},
  errorMessage: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_FETCH:
      return {
        ...state, user: action.payload, errorMessage: ''
      }
      case USER_FETCH_ERROR:
        return {
          ...state, errorMessage: action.error
        }
        default:
          return state;
  }
}