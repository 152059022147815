import React from "react";
import {Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';
import {connect} from 'react-redux';

import history from '../../history';
import {userFetch} from "../../actions";
import {CourseLibraryComponent} from '../course_library_component'
import Header from '../header';
import LessonComponent from '../lesson_component';
import LessonIndexComponent from '../lesson_index_component';
import {FooterComponent} from '../footer_component';

class LMSContentComponent extends React.Component {

  componentDidMount() {
    this.props.userFetch()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user.errorMessage !== this.props.user.errorMessage ) {
      history.push('/sign_in');
    }
  }

  render = () => {
    if (Object.keys(this.props.user.user).length === 0) {
      return null
    }
    ReactGA.initialize('UA-148510445-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (<div>
      <Header/>
      <Switch>
        <Route exact path="/courses" render={props => (<CourseLibraryComponent {...props} currentUser={this.props.user}/>)}/>
        <Route exact path="/courses/:id" component={LessonComponent}/>
        <Route exact path="/courses/:id/lessons" component={LessonIndexComponent}/>
        <Route path="/courses/:id/lessons/:lessonId" render={props => (<LessonComponent {...props} currentUser={this.props.user}/>)}/>
      </Switch>
      <FooterComponent/>
    </div>)
  }
}

const mapStateToProps = (state) => {
  return {user: state.lms.user}
}

export default connect(mapStateToProps, {userFetch})(LMSContentComponent)
