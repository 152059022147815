import axios from 'axios';
import {
  COURSES_FETCH,
  COURSES_FETCH_ERROR,
  RECENT_COURSES_FETCH,
  RECENT_COURSES_FETCH_ERROR,
  COURSE_SELECT,
  COURSE_SELECT_ERROR,
  LESSON_GET_FIRST,
  LESSON_GET_CURRENT,
  LESSON_GET_FIRST_ERROR,
  LESSON_GET_CURRENT_ERROR,
  COURSE_FETCH,
  COURSE_FETCH_ERROR,
  USER_FETCH,
  USER_FETCH_ERROR,
  REQUEST_SECTIONS,
  REQUEST_SECTIONS_ERROR,
  REQUEST_SECTION_LESSONS,
  REQUEST_SECTION_LESSONS_ERROR,
  CLEAR_SECTIONS,
  CLEAR_CURRENT_LESSON
} from './types';
import history from '../history';
import {
  setCookieValueForKey,
  getCookieValueForKey
} from "../clientStorage";

export const getCourseList = () => {
  return async (dispatch) => {
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/courses`,
        withCredentials: true
      });
      const courses = Object.assign({}, ...(response.data.data.map(course => ({
        [course.id]: course
      }))));
      dispatch({
        type: COURSES_FETCH,
        payload: courses
      });
    } catch (e) {
      dispatch({
        type: COURSES_FETCH_ERROR,
        error: 'Error Getting Course List'
      })
    }
  }
}

export const getRecentCourses = () => {
  return async (dispatch) => {
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/courses?page%5Blimit%5D=3`,
        withCredentials: true
      });
      const courses = Object.assign({}, ...(response.data.data.map(course => ({
        [course.id]: course
      }))));
      dispatch({
        type: RECENT_COURSES_FETCH,
        payload: courses
      })
    } catch (error) {
      dispatch({
        type: RECENT_COURSES_FETCH_ERROR,
        error: 'Error Geeting Recent Courses'
      })
    }
  }
}

export const selectCurrentCourse = (course) => {
  try {
    return {
      type: COURSE_SELECT,
      payload: course
    };
  } catch (e) {
    return {
      type: COURSE_SELECT_ERROR,
      error: `Error selecting current course for ${course.id}`
    };
  }
}

export const getFirstLesson = (courseId) => {
  return async (dispatch) => {
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/lessons?filter[resume_lesson]=true,${courseId}&sort=course-lesson-order&include=section,video&page%5Blimit%5D=1`,
        withCredentials: true
      });
      const firstLesson = response.data
      const lessonId = response.data.data[0].id
      dispatch({
        type: LESSON_GET_FIRST,
        payload: firstLesson
      });
      history.push(`/courses/${courseId}/lessons/${lessonId}`)
    } catch (e) {
      dispatch({
        type: LESSON_GET_FIRST_ERROR,
        error: `Error Getting First Course Lesson for ${courseId}`
      })
    }
  }
}

export const getCurrentLesson = (lessonId, courseId, lessonUrl) => {
  if (lessonId) {
    return async (dispatch) => {
      try {
        const cachedToken = getCookieValueForKey("accessToken");
        let response = await axios({
          method: 'get',
          headers: {
            "Authorization": `${cachedToken}`
          },
          url: `${process.env.REACT_APP_LMS_API}/lessons/${lessonId}?filter[course_id]=${courseId}&include=section,video`,
          withCredentials: true
        });
        const currentLesson = response.data
        dispatch({
          type: LESSON_GET_CURRENT,
          payload: currentLesson
        });
      } catch (e) {
        dispatch({
          type: LESSON_GET_CURRENT_ERROR,
          error: `Error Getting Current Lesson for ${courseId}`
        })
      }
    }
  } else {
    return async (dispatch) => {
      try {
        const cachedToken = getCookieValueForKey("accessToken");
        let response = await axios({
          method: 'get',
          headers: {
            "Authorization": `${cachedToken}`
          },
          url: `${lessonUrl}?filter[course_id]=${courseId}&include=section,video`,
          withCredentials: true
        });
        const currentLesson = {
          data: response.data.data,
          included: response.data.included
        }
        const lessonId = currentLesson.data.id
        dispatch({
          type: LESSON_GET_CURRENT,
          payload: currentLesson
        });
        history.push(`/courses/${courseId}/lessons/${lessonId}`)
      } catch (e) {
        dispatch({
          type: LESSON_GET_CURRENT_ERROR,
          error: `Error Getting Current Lesson for ${courseId}`
        })
      }
    }
  }
}

export const getCourseSections = (courseId) => {
  return async (dispatch) => {
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/sections?filter[course_id]=${courseId}&sort=course_section_order`,
        withCredentials: true
      });
      const sections = Object.assign({}, ...(response.data.data.map((section, index) => ({
        [section.id]: {
          ...section,
          sectionIndex: index
        }
      }))));
      dispatch({
        type: REQUEST_SECTIONS,
        payload: sections
      })
    } catch {
      dispatch({
        type: REQUEST_SECTIONS_ERROR,
        error: `Error Getting Course Sections for course: ${courseId}`
      })
    }
  }
}

export const clearSections = () => {
  return {
    type: CLEAR_SECTIONS
  }
}

export const clearCurrentLesson = () => {
  return {
    type: CLEAR_CURRENT_LESSON
  }
}

export const getSectionLessons = (sectionId) => {
  return async (dispatch) => {
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/lessons?filter[section_id]=${sectionId}&sort=course_lesson_order`,
        withCredentials: true
      });
      const lessons = Object.assign({}, ...(response.data.data.map(lesson => ({
        [lesson.id]: lesson
      }))));
      dispatch({
        type: REQUEST_SECTION_LESSONS,
        payload: lessons
      })
    } catch {
      dispatch({
        type: REQUEST_SECTION_LESSONS_ERROR,
        error: `Error Getting Section Lessons for course: ${sectionId}`
      })
    }
  }
}

export const fetchCourse = (courseId) => {
  return async (dispatch) => {
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/courses/${courseId}`,
        withCredentials: true
      });
      const course = response.data.data
      dispatch({
        type: COURSE_FETCH,
        payload: course
      })
    } catch {
      dispatch({
        type: COURSE_FETCH_ERROR,
        error: `Error Fetching Course with id: ${courseId}`
      })
    }
  }
}

export const userFetch = (userEmail, userPassword) => {
  console.log(process.env);
  if (userEmail && userPassword) {
    return async (dispatch) => {
      try {
        let response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_LMS_API}/sessions`,
          data: {
            email: userEmail,
            password: userPassword
          },
          withCredentials: true
        });
        const token = response.data.jwt
        setCookieValueForKey("accessToken", token)
        const user = response.data.user.data
        dispatch({
          type: USER_FETCH,
          payload: user
        })
        history.push("/courses")
      } catch (e) {
        dispatch({
          type: USER_FETCH_ERROR,
          error: e.message
        })
      }
    }
  } else {
    return async (dispatch) => {
      try {
        const cachedToken = getCookieValueForKey("accessToken");
        let response = await axios({
          method: 'get',
          headers: {
            "Authorization": `${cachedToken}`
          },
          url: `${process.env.REACT_APP_LMS_API}/userinfo.json`,
          withCredentials: true
        })
        const user = response.data.data
        dispatch({
          type: USER_FETCH,
          payload: user
        })
      } catch (e) {
        dispatch({
          type: USER_FETCH_ERROR,
          error: e.message
        })
      }
    }
  }
}