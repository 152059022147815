import React from 'react';

import ListGroup from 'react-bootstrap/ListGroup'
import styles from "./styles.module.scss";

export class LessonTextComponent extends React.Component {

  render = (props) => {
    const notes = this.props.text
      ? this.props.text
      : '* No lesson notes available'

    const summary = this.props.summary
      ? this.props.summary
      : 'No lesson summary available'

    return (<ListGroup className="lessonNotes" variant="flush">
      <ListGroup.Item className={styles.lessonSummary}>
        {summary}
      </ListGroup.Item>
      {
        notes.split('*').map((str, index) => {
          if (str === '') {
            return null;
          }
          return (<ListGroup.Item className={styles.lessonNote} key={index}>
            <div className={styles.test}>{str}</div>
          </ListGroup.Item>)
        })
      }
    </ListGroup>)
  }
}
