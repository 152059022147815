import React from 'react';

import {Router, Route, Redirect, Switch} from 'react-router-dom';


import ScrollToTop from './components/scroll_to_top';
import history from './history'
import {SignInComponent} from './components/sign_in_component';
import LMSContentComponent from './components/LMS_content_component';
import "./styles.scss";

const App = () => {

  return (<div className="App">
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route exact path="/sign_in" component={SignInComponent}/>

          <Route path="/courses" render={(props) => {
            return <LMSContentComponent {...props}/>
          }}/>

          <Redirect exact from='/' to='/sign_in'/>
        </Switch>
      </ScrollToTop>
    </Router>
  </div>)
}

export default App;
