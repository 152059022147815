import React from 'react';
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { connect } from 'react-redux';
import axios from 'axios';

import {
  getCurrentLesson,
  getCourseSections,
  clearCurrentLesson,
  clearSections
} from '../../actions';
import { currentLessonAndIncludeState } from '../../selectors/currentLessonAndInclude';
import LessonIndexComponent from "../lesson_index_component";
import { VideoComponent } from "../video_component";
import { LessonTextComponent } from "../lesson_text_component";
import AboutCourseContainer from "../about_course_container";
import CourseProfessorComponent from "../course_professor_component"
import styles from "./styles.module.scss";

class LessonComponent extends React.Component {

  state = {
    key: 0,
    showCourseContent: true,
    routeChanged: false,
    completedLessons: []
  };

  componentDidMount() {
    const courseId = this.props.match.params.id;
    const lessonId = this.props.match.params.lessonId;
    this.props.getCurrentLesson(lessonId, courseId, null);
    this.props.getCourseSections(courseId);
  }

  componentWillUnmount() {
    this.props.clearSections();
    this.props.clearCurrentLesson();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const courseId = this.props.match.params.id;
      const lessonId = this.props.match.params.lessonId;
      this.props.getCurrentLesson(lessonId, courseId, null);
    }
  }

  updateCurrentLessonId = () => {
    const currentLessonId = this.props.match.params.lessonId;
    if (this.startTimerLessonId !== currentLessonId) {
      this.startTimerLessonId = currentLessonId
    }
  }

  sendLessonEvent = () => {
    const userId = this.props.currentUser.user.id;
    const courseId = this.props.match.params.id;
    const lessonId = this.props.currentLesson.content.lesson.id
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_LMS_API}/lesson_events`,
      data: {
        user_id: userId,
        lesson_id: lessonId,
        course_id: courseId
      }
    })
  }

  getVimeoUrl = () => {
    const includedVideo = this.props.currentLesson.content.video
    if (typeof(includedVideo) === 'undefined') {
      return null
    } else {
      return includedVideo.attributes['vimeo-video-url']
    }
  }

  getVideoBullets = () => {
    const includedVideo = this.props.currentLesson.content.video
    if (typeof(includedVideo) === 'undefined') {
      return '* No Lesson Notes'
    } else {
      return includedVideo.attributes['video-summary-points']
    }
  }

  getLessonSummary = () => {
    const includedVideo = this.props.currentLesson.content.video
    if (typeof(includedVideo) === 'undefined') {
      return '* No Lesson Notes'
    } else {
      return includedVideo.attributes['video-summary-opener']
    }
  }

  previousLessonHandler = () => {
    const prevLessonUrl = this.props.currentLesson.content.lesson.links.prev;
    if (!prevLessonUrl) {
      return;
    }
    const courseId = this.props.match.params.id
    this.props.getCurrentLesson(null, courseId, prevLessonUrl);
  }

  nextLessonHandler = () => {
    const nextLessonUrl = this.props.currentLesson.content.lesson.links.next;
    if (!nextLessonUrl) {
      return;
    }
    const courseId = this.props.match.params.id;
    this.props.getCurrentLesson(null, courseId, nextLessonUrl);
  }

  updateCompletedLessons = () => {
    const lessonId = this.props.match.params.lessonId;
    this.setState({
      completedLessons: [...this.state.completedLessons, lessonId]
    })
  }

  render = () => {
    const author = this.props.currentLesson.content.video ?
      this.props.currentLesson.content.video.attributes.author :
      null;
    return (
      this.props.currentLesson.content.lesson ?
        <Container fluid="true">
          <Row>
            <Col
              className={styles.indexAndNotesContainer}
              lg={{span: 3, order: 1}}
              xs={{span: 12, order: 2}}
            >
              <Row>
                {
                  author ?
                  <CourseProfessorComponent
                    instructorThumbnail={author.headshot_url}
                    instructorName={author.name}
                    instructorSumm={author.bio}
                    instructorCreds={author.credentials}
                    instructorShortBio={author.short_bio}
                  /> :
                  null
                }
                <Tabs
                  className={[`${"pl-1"}`, `${"pr-1"}`, styles.buttonText]}
                  defaultActiveKey={0}
                  onSelect={(k) => this.setState({key: k})}
                >
                  <Tab
                    className={styles.tabItem}
                    eventKey={0}
                    title="Course Content"
                  >
                    <div className={styles.sectionsContainer}>
                      <LessonIndexComponent
                        sections={this.props.sections}
                        courseId={this.props.match.params.id}
                        lessonId={this.props.match.params.lessonId}
                        sectionId={
                          this.props.currentLesson.content.lesson
                          .attributes["section-id"]
                        }
                        pathName={this.props.location.pathName}
                        completedLessons={this.state.completedLessons}
                      />
                    </div>
                  </Tab>
                  <Tab
                    eventKey={1}
                    title="Lesson Notes"
                    className={styles.tabItem}
                  >
                    <div className={styles.sectionsContainer}>
                      <LessonTextComponent
                        text={this.getVideoBullets()}
                        summary={this.getLessonSummary()}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </Row>
            </Col>
            <Col lg={{span: 9, order: 2}} xs={{span: 12, order: 1}}>
              <Row className={styles.rowPlayer}>
                <Col xs={{span: 1, order: 1}} className={styles.navColPrev}>
                  <IoIosArrowDropleft
                  className="prev-next-icon prev-icon"
                  onClick={this.previousLessonHandler}
                  size="2.25em"
                  color="#FFFFFF"
                  />
                </Col>
                <Col xs={{span: 10, order: 2}}>
                  {this.props.currentLesson ?
                    <VideoComponent
                      playing={true}
                      url={this.getVimeoUrl()}
                      onEnded={this.nextLessonHandler}
                      lessonCompletitionCheck={this.updateCompletedLessons}
                      sendLessonEvent={this.sendLessonEvent}
                      lessonId={this.props.currentLesson.content.lesson}
                    /> :
                    null
                  }
                </Col>
                <Col xs={{span: 1, order: 3}} className={styles.navColNext}>
                  <IoIosArrowDropright
                    className="prev-next-icon next-icon"
                    onClick={this.nextLessonHandler}
                    size="2.25em"
                    color="#FFFFFF"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <AboutCourseContainer
                    currentLesson={this.props.currentLesson}
                    courseId={this.props.match.params.id}
                    text={this.getVideoBullets()}
                    summary={this.getLessonSummary()}
                    sections={this.props.sections}
                    lessonId={this.props.match.params.lessonId}
                    pathName={this.props.location.pathName}
                    sectionId={
                      this.props.currentLesson.content.lesson
                      .attributes["section-id"]
                    }
                    completedLessons={this.state.completedLessons}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container> :
      null
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentLesson: currentLessonAndIncludeState(state),
    sections: state.lms.courseSections.sections
  }
}

export default connect(mapStateToProps, {
   getCurrentLesson, getCourseSections, clearCurrentLesson, clearSections
 })(LessonComponent)
