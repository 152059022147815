import React from 'react';

import AboutCourseComponent from '../about_course_component';
import {LessonTextComponent} from '../lesson_text_component';
import LessonIndexComponent from '../lesson_index_component';
import styles from './styles.module.scss';

class AboutCourseContainer extends React.Component {
  state = {
    activeTab: 'ABOUT_TAB',
    width: 0,
    height: 0
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
    if (this.state.width >= 992) {
      this.setState({activeTab: 'ABOUT_TAB'})
    }
  }

  getActiveTab = () => {
    switch (this.state.activeTab) {
      case 'ABOUT_TAB':
        return (<AboutCourseComponent currentLesson={this.props.currentLesson} courseId={this.props.courseId}/>)
      case 'LESSON_TEXT':
        return (<LessonTextComponent text={this.props.text} summary={this.props.summary}/>)
      case 'LESSON_INDEX':
        return (<LessonIndexComponent sections={this.props.sections} courseId={this.props.courseId} lessonId={this.props.lessonId} pathName={this.props.pathName} completedLessons={this.props.completedLessons} sectionId={this.props.currentLesson.content.lesson.attributes["section-id"]}/>)
      default:
        return (<LessonTextComponent text={this.props.text} summary={this.props.summary}/>)
    }
  }

  render = () => {
    return (<div>
      <div className={styles.scrollAbleNav}>
        <div className="pt-1 pb-1 justify-content-center">
          <div onClick={() => {
              this.setState({activeTab: 'ABOUT_TAB'})
            }} className={this.state.activeTab === 'ABOUT_TAB'
              ? `${ "ml-1"}, ${styles.navBttn}, ${styles.actBttn}`
              : `${ "ml-1"}, ${styles.navBttn}`
          }>
            Overview
          </div>
          <div onClick={() => {
              this.setState({activeTab: 'LESSON_INDEX'})
            }} className={this.state.activeTab === 'LESSON_INDEX'
              ? `${styles.navBttn},  ${styles.hideOnLg}, ${styles.actBttn}`
              : `${styles.navBttn}, ${styles.hideOnLg}`
          }>
            Course Content
          </div>
          <div onClick={() => {
              this.setState({activeTab: 'LESSON_TEXT'})
            }} className={this.state.activeTab === 'LESSON_TEXT'
              ? `${styles.navBttn}, ${styles.hideOnLg}, ${styles.actBttn}`
              : `${styles.navBttn}, ${styles.hideOnLg}`
          }>
            Lesson Notes
          </div>
        </div>
      </div>
      <div>
        {this.getActiveTab()}
      </div>
    </div>)
  }
}

export default AboutCourseContainer;
