import React from 'react';
import {IoIosArrowBack, IoIosBook, IoIosListBox, IoIosBookmark, IoIosLogOut} from "react-icons/io";
import {LinkContainer} from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {connect} from 'react-redux';

import history from '../../history';
import {expireCookieValueForKey} from "../../clientStorage";
import {currentLessonAndIncludeState} from '../../selectors/currentLessonAndInclude';
import styles from "./styles.module.scss";
import logo from '../../assets/swiftIcon.svg';

class Header extends React.Component {

  backHomeHandler = () => {
    history.push("/courses")
  }

  getSectionOrder = (lesson) => {
    const currentSection = this.props.sections[lesson.attributes['section-id']];
    return currentSection.sectionIndex + 1;
  }

  render = (props) => {

    const courseName = this.props.currentCourse.attributes
      ? this.props.currentCourse.attributes.name
      : null

    const lessonName = this.props.currentLesson.content.lesson
      ? this.props.currentLesson.content.lesson.attributes.title
      : null

    const sectionOrder = (this.props.currentLesson.content.lesson && Object.keys(this.props.sections).includes(this.props.currentLesson.content.lesson.attributes['section-id']))
      ? this.getSectionOrder(this.props.currentLesson.content.lesson)
      : null

    return (<div className={styles.headerContainer}>
      <Navbar className="bg-swift-secondary" variant="dark" expand="md" sticky="top">
        <IoIosArrowBack className={history.location.pathname === '/courses'
            ? styles.hideBackButton
            : styles.backButton
} onClick={this.backHomeHandler} size="2.25em" color="#FFFFFF"/>
        <div className={history.location.pathname !== '/courses'
            ? styles.homeContainer
            : null
}>
          <LinkContainer to="/courses">
            <img src={logo} alt="Swift Logo" className={styles.swiftLogo}/>
          </LinkContainer>;
          <LinkContainer to="/courses" className={`${styles.headerHome}`}>
            <Navbar.Brand>Swift Medical Academy</Navbar.Brand>
          </LinkContainer>
        </div>
        {
          history.location.pathname !== '/courses'
            ? <Nav.Item className={[styles.lessonInfo]}>
                <span className={styles.pipe}>|</span>
                <p className={`${styles.sectionXlessonInfo} d-inline-block text-truncate`}>
                  <span className={styles.courseXsection}>
                    <IoIosBook className={styles.bookIcon} size="1.5em" color="#FFFFFF"/>{courseName}
                    <IoIosListBox className={styles.listIcon} size="1.5em" color="#FFFFFF"/>Section {sectionOrder}
                  </span>
                  <IoIosBookmark className={styles.bookmarkIcon} size="1.5em" color="#FFFFFF"/>{lessonName}
                </p>
              </Nav.Item>
            : null
        }
        <LinkContainer to='/sign_in'
          onClick={() => expireCookieValueForKey("accessToken")}
        >
          <div className={styles.logOutContainer}>
            <IoIosLogOut className={styles.logOutIcon}/>
          </div>
        </LinkContainer>
      </Navbar>
    </div>)
  }
}

const mapStateToProps = (state) => {
  return {currentLesson: currentLessonAndIncludeState(state), currentCourse: state.lms.fetchedCourse.course, sections: state.lms.courseSections.sections}
}

export default connect(mapStateToProps, {})(Header)