import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge'
import Image from 'react-bootstrap/Image'

import styles from "./styles.module.scss";

class CourseProfessorComponent extends React.Component {
  render = () => {
    return (<Container fluid={true}>
      <Row>
        <Col xs={6} className="">
          <div className={styles.lessonProf}>
            <h4>
              Instructor
            </h4>
          </div>
        </Col>
        <Col xs={6} className="">
          <div className={styles.instructorName}>
            <h5>
              {this.props.instructorName}
            </h5>
          </div>
        </Col>
        <Col xs={6}>
          <div className={styles.instructorImg}>
            <Image src={this.props.instructorThumbnail} fluid="fluid" roundedCircle="roundedCircle"/>
          </div>
        </Col>
        <Col xs={6}>
          <div className={styles.instructorCerts}>
            {this.props.instructorCreds.map((cred, index) => {
              return (
                <div className="p-1" key={index}>
                  <Badge variant="light"><h6>{cred}</h6></Badge>
                </div>
              )
            })}
          </div>
        </Col>
        <Col>
          <div className={styles.instructorSumm}>
            {this.props.instructorShortBio}
          </div>
        </Col>
      </Row>
    </Container>)
  }
}

export default CourseProfessorComponent;