import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {IoIosSearch} from "react-icons/io";

import styles from './styles.module.scss';

class InputFieldComponent extends React.Component {

  render = () => {
    return (<Container className="" fluid="true">
      <Row className="no-gutters">
        <Col xs={9}>
          <input type="text" className={styles.inputField} placeholder="Search Courses" onChange={(event) => this.props.handleChange(event)} value={this.props.inputValue}/>
        </Col>
        <Col xs={3}>
          <button className={styles.search}>
            <span className={styles.searchText}>Search</span>
            <IoIosSearch className={styles.searchIcon} size="1.5em" color="#FFFFFF"/>
          </button>
        </Col>
      </Row>
    </Container>)
  }
}

export default InputFieldComponent;
