import {
  COURSE_SELECT,
  COURSE_SELECT_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  course: {},
  errorMessage: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COURSE_SELECT:
      return {
        ...state, course: action.payload
      }
      case COURSE_SELECT_ERROR:
        return {
          ...state, course: action.payload
        }
        default:
          return state;
  }
}