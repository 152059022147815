import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {IoIosSearch, IoIosCloseCircleOutline} from "react-icons/io";

import styles from './styles.module.scss';
import InputFieldComponent from '../input_field_component';
import {getCookieValueForKey} from "../../clientStorage";

class SearchComponent extends React.Component {

  state = {
    inputValue: '',
    searchResults: {}
  }

  exitSearch = () => this.setState({inputValue: '', searchResults: {}})

  handleChange = (event) => {
    this.setState({
      inputValue: event.target.value
    }, this.updateSearchResults)
  }

  updateSearchResults = async () => {
    if (this.state.inputValue.trim().length < 1) {
      this.setState({searchResults: {}})
      return;
    }
    if (this.state.inputValue.trim().length < 3) {
      return;
    }
    try {
      const cachedToken = getCookieValueForKey("accessToken");
      let response = await axios({
        method: 'get',
        headers: {
          "Authorization": `${cachedToken}`
        },
        url: `${process.env.REACT_APP_LMS_API}/lessons?filter[query]=${this.state.inputValue}&include=video&page%5Blimit%5D=25`,
        withCredentials: true
      });
      const results = response.data
      this.setState({searchResults: results})
    } catch (error) {
      console.log(error);
    }
  }

  regex = (query) => new RegExp(`${query}`, 'ig');

  highlightedText = (videoSnippet, inputValue) => {
    const index = this.regex(inputValue).exec(videoSnippet).index
    const startIndex = (index - 60) >= 0
      ? (index - 60)
      : 0;
    const endIndex = (index + 60) < (videoSnippet.length)
      ? (index + 60)
      : (videoSnippet.length - 1)
    return (<div style={{
        font: 'Roboto',
        color: '#3D78AD'
      }}>
      <small>
        {
          inputValue.length > 2
            ? videoSnippet.substring(startIndex, endIndex).replace(/\*/g, '\u2022').replace(new RegExp(`${inputValue}`, 'ig'), `${ '|<**>|' + inputValue + '|<**>|'}`).split('|<**>|').map((word, index) => {
              if (word.toLowerCase() === inputValue.toLowerCase()) {
                return (<span key={index}>
                  <span>{' '}</span>
                  <mark style={{
                      backgroundColor: '#A6B6ED'
                    }}>
                    {word.trim()}
                  </mark>
                </span>)
              } else {
                return (<span key={index}>
                  {word.trim()}
                </span>)
              }
            })
            : videoSnippet.substring(startIndex, endIndex).replace(/\*/g, '\u2022')
        }
      </small>
    </div>)
  }

  render = () => {
    return (<div>
      <InputFieldComponent handleChange={this.handleChange} inputValue={this.state.inputValue}/>
      <div className={styles.searchResponseContainer}>
        {
          this.state.searchResults.included
            ? <ul>
                <div className={styles.exitButton} onClick={this.exitSearch}>
                  <IoIosCloseCircleOutline/>
                </div>
                {
                  this.state.searchResults.data.map((lesson, lessonIndex) => {
                    const videos = Object.assign({}, ...this.state.searchResults.included.map((video) => {
                      return {
                        [video.id]: video
                      }
                    }));
                    const videoTitle = videos[lesson.attributes['video-id']].attributes.name
                    const videoSnippet = videos[lesson.attributes['video-id']].attributes['video-summary-points']
                    const courseId = lesson.attributes['first-course-id']
                    const inputValue = this.state.inputValue;

                    return (<li key={lesson.id} className={styles.searchListItems}>
                      <Link className="" style={{
                          textDecoration: 'none'
                        }} to={`/courses/${courseId}/lessons/${lesson.id}`}>
                        <div>
                          <div style={{
                              lineHeight: '1.25',
                              font: 'Roboto'
                            }} className="pt-3">
                            <IoIosSearch size="1em" color="#000000" style={{
                                marginRight: '0.5em'
                              }}/> {videoTitle}
                          </div>
                          <div className="pt-0" style={{
                              overflowWrap: 'normal',
                              lineHeight: '1.25'
                            }}>
                            {
                              videoSnippet
                                ? videoSnippet.match(this.regex(inputValue))
                                  ? this.highlightedText(videoSnippet, inputValue)
                                  : <small style={{
                                        font: 'Roboto',
                                        color: '#3D78AD'
                                      }}>
                                      {
                                        videoSnippet.replace(/\*/g, '\u2022').substring(
                                          0, videoSnippet.length > 120
                                          ? 120
                                          : videoSnippet.length)
                                      }
                                    </small>
                                : null
                            }
                          </div>
                        </div>
                      </Link>
                    </li>)
                  })
                }
              </ul>
            : null
        }
      </div>
    </div>)
  }
}

export default SearchComponent;
