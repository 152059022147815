import _ from 'lodash';
import {
  createSelector
} from 'reselect';
// selector
const firstCurrentLessonAndInclude = (state) => {
  try {
    return {
      content: {
        lesson: state.lms.lesson.firstLesson.data[0],
        video: _.filter(state.lms.lesson.firstLesson.included, {
          'type': 'videos'
        })[0],
        section: _.filter(state.lms.lesson.firstLesson.included, {
          'type': 'sections'
        })[0]
      }
    }
  } catch (e) {
    return
  }
}
// reselect function
export const firstCurrentLessonAndIncludeState = createSelector(
  [firstCurrentLessonAndInclude],
  (lessonAndInclude) => lessonAndInclude
)