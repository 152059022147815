import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import styles from "./styles.module.scss";
import logo from '../../assets/swiftIcon.svg';

export class FooterComponent extends React.Component {

  render = () => {
    return (<Container className={[`${ "bg-footer-grey"}`, styles.test]} fluid="true">
      <Row className="text-xs-center">
        <Col xs={{
            span: 12,
            offset: 0
          }} md={{
            span: 6,
            offset: 0
          }}>
          <img src={logo} alt="Swift Logo"/>
          <span className={[styles.footerTitle]}>Swift Medical Academy</span>
        </Col>
        <Col xs={{
            span: 12,
            offset: 0
          }} md={{
            span: 3,
            offset: 0
          }}>
          <small className={[styles.footerCR]}>&copy; 2019 Swift Medical</small>
        </Col>
        <Col xs={{
            span: 12,
            offset: 0
          }} md={{
            span: 3,
            offset: 0
          }}>
          <small className={[styles.footerContact]}>
            <a href={'https://swiftmedical.com/contact/'} target="_blank" rel="noopener noreferrer">
              Help and Support
            </a>
          </small>
        </Col>
      </Row>
    </Container>)
  }
}
