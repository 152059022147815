import {
  REQUEST_SECTIONS,
  REQUEST_SECTIONS_ERROR,
  CLEAR_SECTIONS
} from '../../actions/types';

const INITIAL_STATE = {
  sections: {},
  errorMessage: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_SECTIONS:
      return {
        ...state, sections: action.payload
      }
      case REQUEST_SECTIONS_ERROR:
        return {
          ...state, errorMessage: action.error
        }
        case CLEAR_SECTIONS:
          return {
            ...state, sections: {}
          }
          default:
            return state;
  }
}