import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar'
import {IoIosPlay, IoLogoApple, IoIosDesktop, IoIosArrowForward, IoIosArrowBack} from "react-icons/io";
import {MdAndroid} from "react-icons/md";
import 'react-circular-progressbar/dist/styles.css';

import styles from './styles.module.scss';

function ControlledCarousel(props) {

  const platforms = (platformsArr) => {
    return platformsArr.map((platform, index) => {
      if (platform === 'ios') {
        return (<IoLogoApple size="2.5em" color="#2E3C63" key={index}/>)
      } else if (platform === 'android') {
        return (<MdAndroid size="2.5em" color="#2E3C63" key={index}/>)
      } else {
        return (<IoIosDesktop size="2.5em" color="#2E3C63" key={index}/>)
      }
    })
  }

  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  let recentCourses;

  if (props.recentCourses) {
    recentCourses = Object.values(props.recentCourses);
  }

  return (
    props.recentCourses
    ? <Carousel activeIndex={index} direction={direction} onSelect={handleSelect} nextIcon={<IoIosArrowForward className = {
        styles.carouselIcon
      } />} prevIcon={<IoIosArrowBack className = {
        styles.carouselIcon
      } />} className={styles.courseCarousel} indicators={false} interval={17500}>
      {
        recentCourses.map((course) => {
          const courseId = course.id;
          const courseName = course.attributes.name;
          const courseDescription = course.attributes.description;
          const percentCompleted = course.attributes['percent-completed'];
          const platformsArr = course.attributes.platforms;
          return (<Carousel.Item className={styles.carouselCourseItem} key={courseId}>
            <Col sm="12" md="12" lg="12" className={styles.courseItemCol}>
              <Card className={[`${ "mb-5"}`, styles.courseItemContainer, `${ "bg-swift-grey"}`]}>
                <Card.Header>Recently Accessed Course</Card.Header>
                <Card.Body className={[`
                    ${ 'text-swift-primary'}`, styles.descriptionContainer]}>
                  <Card.Title className="text-swift-primary">
                    {courseName}
                  </Card.Title>
                  <Card.Text className={[`
                      ${ 'text-swift-primary'}`]}>
                    {courseDescription}
                  </Card.Text>
                </Card.Body>
                <div className={styles.platformsContainer}>
                  {platforms(platformsArr)}
                </div>
                <div className={styles.buttonContainer}>
                  <div className="d-inline-flex align-items-center">
                    <div className={[`${ "btn-group"} ${styles.playButton}`]}>
                      <button onClick={() => props.getCurrentCourseAndLesson(course)} className="btn text-white">
                        {
                          percentCompleted > 0 && percentCompleted < 100
                            ? 'Resume'
                            : 'Play'
                        }
                        <IoIosPlay className={styles.playCourseIcon} size="1.25em" color="#FFFFFF"/>
                      </button>
                    </div>
                  </div>
                </div>
                <ProgressBar now={percentCompleted} label={`${percentCompleted}%`} variant="swift-primary"/>
              </Card>
            </Col>
          </Carousel.Item>)
        })
      }
    </Carousel>
    : null);
}

export default ControlledCarousel;
