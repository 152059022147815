import {
  REQUEST_SECTION_LESSONS,
  REQUEST_SECTION_LESSONS_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  lessons: {},
  errorMessage: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_SECTION_LESSONS:
      return {
        ...state, lessons: action.payload
      }
      case REQUEST_SECTION_LESSONS_ERROR:
        return {
          ...state, errorMessage: action.error
        }
        default:
          return state;
  }
}