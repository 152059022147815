import React from 'react';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge'

import {fetchCourse} from '../../actions';
import styles from './styles.module.scss';

class AboutCourseComponent extends React.Component {

  getAboutTargetCourse = () => {
    const courseId = this.props.courseId
      ? this.props.courseId
      : this.match.props.params.id
    this.props.fetchCourse(courseId)
  }

  componentDidMount() {
    this.getAboutTargetCourse();
  }

  render = () => {
    const course = this.props.fetchedCourse.course;
    const author = this.props.currentLesson.content.video.attributes['author'];
    const tags = this.props.currentLesson.content.video.attributes['tags'];
    const title = this.props.currentLesson.content.lesson.attributes.title;
    return (<Container className="pt-5" fluid="true">
      {
        course.id
          ? <Row>
              <Col xs={{
                  span: 10,
                  offset: 1
                }}>
                <Row className="">
                  <h5>{title}</h5>
                </Row>
                <Row className={styles.aboutCourseSection}>
                  <Col xs={{
                      span: 12,
                      offset: 0
                    }} md={{
                      span: 4,
                      offset: 0
                    }}>
                    <div className={styles.sectionTitle}>
                      Instructor
                    </div>
                  </Col>
                  <Col xs={{
                      span: 12,
                      offset: 0
                    }} md={{
                      span: 6,
                      offset: 0
                    }} className="ml-3">
                    {
                      author
                        ? <> < Row className = {
                          styles.instuctorRow
                        } > <img className={styles.instructorHeadshot} src={author.headshot_url} alt="instructor pic"/>
                          <div className={styles.instructorTitle}>
                            <p>{author.name}</p>
                            <p>{author.title}</p>
                          </div>
                        </Row>
                        <Row className={styles.sectioBody}>
                          <div className="pt-4">
                            {
                              author.bio.split('\n').map((item, i) => {
                                return <p key={i}>{item}</p>
                              })
                            }
                          </div>
                        </Row>
                      </>
                        : null
                    }
                  </Col>
                </Row>
                <Row className={styles.aboutCourseSection}>
                  <Col xs={{
                      span: 4,
                      offset: 0
                    }}>
                    <div className={styles.sectionTitle}>
                      Description
                    </div>
                  </Col>
                  <Col xs={{
                      span: 6,
                      offset: 0
                    }} className={styles.sectioBody}>
                    <p>{course.attributes.description}</p>
                  </Col>
                </Row>
                <Row className={styles.aboutCourseSection}>
                  <Col xs={{
                      span: 4,
                      offset: 0
                    }}>
                    <div className={styles.sectionTitle}>
                      Details
                    </div>
                  </Col>
                  <Col xs={{
                      span: 6,
                      offset: 0
                    }} className={styles.sectioBody}>
                    <p>{course.attributes['sub-title']}</p>
                  </Col>
                </Row>
                <Row className={styles.aboutCourseSection}>
                  <Col xs={{
                      span: 4,
                      offset: 0
                    }}>
                    <div className={styles.sectionTitle}>
                      Tags
                    </div>
                  </Col>
                  <Col xs={{
                      span: 6,
                      offset: 0
                    }} className={styles.sectioBody}>
                    {
                      tags.map((tag, index) => {
                        return (<Badge key={index} pill="pill" variant="swift-primary" style={{
                            margin: '0.25em',
                            fontSize: '1em'
                          }}>
                          {tag}
                        </Badge>)
                      })
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          : null
      }
    </Container>)
  }
}

const mapStateToProps = (state) => {
  return {fetchedCourse: state.lms.fetchedCourse}
}

export default connect(mapStateToProps, {fetchCourse})(AboutCourseComponent)
