import React from 'react';

import CourseListComponent from '../course_list_component'

export class CourseLibraryComponent extends React.Component {

  render = () => {
    return (<CourseListComponent currentUser={this.props.currentUser}/>)
  }
}
