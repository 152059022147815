import {
  COURSE_FETCH,
  COURSE_FETCH_ERROR
} from '../../actions/types';

const INITIAL_STATE = {
  course: {},
  errorMessage: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COURSE_FETCH:
      return {
        ...state, course: action.payload
      }
      case COURSE_FETCH_ERROR:
        return {
          ...state, course: action.payload
        }
        default:
          return state;
  }
}