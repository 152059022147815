import React from 'react';
import ReactPlayer from 'react-player';

import "./styles.scss";

export class VideoComponent extends React.Component {

  state = {
    lessonEventPosted: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lessonId !== this.props.lessonId) {
      this.setState({lessonEventPosted: false})
    }
  }

  handleProgress = (progress) => {
    if (progress.played >= 0.65 && !this.state.lessonEventPosted) {
      this.setState({lessonEventPosted: true})
      this.props.sendLessonEvent();
      this.props.lessonCompletitionCheck();
    }
  }

  render = () => {

    return (<div className='player-wrapper'>
      <ReactPlayer playing={this.props.playing} className='react-player' url={this.props.url} width='100%' height='100%' muted={false} onEnded={this.props.onEnded} onProgress={this.handleProgress} config={{
          vimeo: {
            playerOptions: {}
          }
        }}/>
    </div>)
  }
}
