import {
  LESSON_GET_FIRST,
  LESSON_GET_CURRENT,
  LESSON_GET_FIRST_ERROR,
  LESSON_GET_CURRENT_ERROR,
  CLEAR_CURRENT_LESSON
} from '../../../actions/types';

const INITIAL_STATE = {
  firstLesson: {},
  currentLesson: {},
  errorMessage: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LESSON_GET_FIRST:
      return {
        ...state, firstLesson: action.payload
      }
      case LESSON_GET_CURRENT:
        return {
          ...state, currentLesson: action.payload
        }
        case LESSON_GET_FIRST_ERROR:
          return {
            ...state, errorMessage: action.error
          }
          case LESSON_GET_CURRENT_ERROR:
            return {
              ...state, errorMessage: action.error
            }
            case CLEAR_CURRENT_LESSON:
              return {
                ...state, currentLesson: {}
              }
              default:
                return state;
  }
}