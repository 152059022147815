import _ from 'lodash';
import {
  createSelector
} from 'reselect';
// selector
const currentLessonAndInclude = (state) => {
  try {
    return {
      content: {
        lesson: state.lms.lesson.currentLesson.data,
        video: _.filter(state.lms.lesson.currentLesson.included, {
          'type': 'videos'
        })[0],
        section: _.filter(state.lms.lesson.currentLesson.included, {
          'type': 'sections'
        })[0]
      }
    }
  } catch (e) {
    return
  }
}
// reselect function
export const currentLessonAndIncludeState = createSelector(
  [currentLessonAndInclude],
  (lessonAndInclude) => lessonAndInclude
)